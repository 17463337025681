import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { Subject } from 'rxjs/internal/Subject';
import { UsuarioPassChangeComponent } from './components/usuarios/usuario-pass-change/usuario-pass-change.component';
import { UsuarioProfileComponent } from './components/usuarios/usuario-profile/usuario-profile.component';
import { NotificacionService } from './utils/notificacion/notificacion.service';
import { SesionService } from './utils/sesion/sesion.service';
import { ThemeService } from './utils/theme/theme.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { VersionComponent } from './components/usuarios/version/version.component';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

import { NgSelectConfig } from '@ng-select/ng-select';
import { Chart } from 'chart.js';
// import { Chart, registerables } from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  notificacionCheques = [];
  notificacionCuotas = [];
  notificacionGamelasArrendadas = [];
  notificacionGamelasPrestadas = [];
  notificacionProductosCriticos = [];
  notificacionGamelasCriticas = [];
  notificacionCreditos = [];

  numNotificaciones: number = 0;
  notificacion = [];

  title = 'kuv-f-base';

  routes: any[] = [];

  loadingNotifications = false;

  constructor(
    public sesion: SesionService,
    private router: Router,
    private loading: LoadingService,
    private alerts: AlertService,
    private themeService: ThemeService,
    private modalService: NgbModal,
    private notificacionService: NotificacionService,
    private ngSelectConfig: NgSelectConfig,
  ) {
    // Chart.register(ChartDataLabels);
    this.ngSelectConfig.notFoundText = 'Ningún elemento encontrado.';
  }

  ngOnInit() {
    // Chart.register(ChartDataLabels);
    // Chart.register(...registerables);
    this.ngSelectConfig.notFoundText = 'Ningún elemento encontrado.';
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);

    // this.getNotification();
    this.getNoticationCount();

    this.sesion.observa.subscribe((res: any[]) => {
      this.routes = this.formatMenuResponse(res);
    });

    if(this.sesion.token){
      this.sesion.getMenuByUser().subscribe((resMenu: any) => {
        this.sesion.saveSesion(this.sesion.token, this.sesion.id, this.sesion.username, resMenu.result, this.sesion.rol);
      });
    }
    this.sesion.loadDataSesion();
  }

  formatMenuResponse(response: any[]) {
    let format: any[] = [];
    response.forEach(element => {
      try {
        if (!element.menu_id) {
          let formatElement = {
            id: element.id,
            text: element.name,
            icon: element.icon,
            route: element.route,
            children: undefined
          };
          format.push(formatElement);
        } else {
          let index = format.findIndex((e) => e.id == element.menu_id);
          if (!format[index].children) {
            format[index].children = [];
          }
          format[index].children.push({
            text: element.name,
            icon: element.icon,
            route: element.route
          });
        }
      } catch (error) {
        console.log('ERROR',element)
      }
    });
    return format;
  }

  getNotification() {
    if(this.sesion.token){
      this.loadingNotifications = true;
      this.notificacionService.index().subscribe((res: any) => {
        this.loadingNotifications = false;
        this.notificacionCheques = res.cheques;
        this.notificacionCreditos = res.creditos;
        this.notificacionCuotas = res.deudas;
        this.notificacionGamelasArrendadas = res.gamelas_arrendadas;
        this.notificacionGamelasPrestadas = res.gamelas_prestadas;
        this.notificacionProductosCriticos = res.productos_criticos;
        this.notificacionGamelasCriticas = res.gamelas_criticas;
      }, err => {
        this.loadingNotifications = false;
        let error = '';
        if(typeof(err.error) == 'string'){
          error += err.error;
        } else if (err.error.length > 0) {
          error += err.error[0];
        }
        this.alerts.addAlert('No se cargaron las notificaciones:  ' + error, "danger");
        // this.alerts.addAlert(err.error, "danger");
      });
    }
  }

  getNoticationCount() {
    if (this.sesion.token) {
      this.notificacionService.count().subscribe((res: any) => {
        this.numNotificaciones = res;
      }, err => {});
    }

    setTimeout((element, index) => {
      this.getNoticationCount();
    }, 5000);
  }

  estaVencida(element) {
    let now = new Date().toISOString().substr(0, 10);
    if (element.vencimiento) return element.vencimiento <= now;
    else if (element.fecha) return element.fecha <= now;
    else return false;
  }

  formatoFecha(string) {
    return new Date(string).toLocaleDateString();
  }

  changePass() {
    let modalRef = this.modalService.open(UsuarioPassChangeComponent, { centered: true });
    modalRef.result.then((result) => { }, (reason) => { })
  }

  verPerfil() {
    let modalRef = this.modalService.open(UsuarioProfileComponent, { centered: true, size: 'lg' });
    modalRef.result.then((result) => { }, (reason) => { })
  }

  verVersion() {
    let modalRef = this.modalService.open(VersionComponent, { centered: true, size: 'md' });
    modalRef.result.then((result) => { }, (reason) => { })
  }

  logout() {
    this.loading.show();
    this.sesion.clearSesion();
    setTimeout(() => {
      this.loading.hide();
      this.router.navigate(['login']);
    }, 500);
  }

  getLink(element): string {
    if (element.stocklocal <= element.stockMinimo) {
      return 'stock-Arica';
    } else if (element.stockexterno <= element.stockMinimo) {
      return 'stock-Santiago';
    }
    return '';
  }
}
