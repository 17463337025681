<div class="background div h-100 w-100">
    <div class="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
        <div class="bienvenida">Bienvenido</div>
    </div>
    <div class="tomandino" (click)="onClickEvent()">
        <img [src]="srcIMG" alt="Tomandino">
    </div>

    <iframe width="560" height="315" src="https://www.youtube.com/embed/txfdGlxEsG8?start=31&amp;end=38&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen *ngIf="times > 26" style="opacity: 0"></iframe>


    <div *ngIf="times > 26" style="position: fixed; left: 0; top: 0; display: flex; flex-wrap: wrap;">
        <img [src]="srcIMG" (click)="onClickEvent()" *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,1,1,1,1,1,1,1,1,1,1,1,1]">
    </div>
</div>