import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  // urlBase = "http://sistema.agrivem.cl/backend/"; // PREPROD
  // urlNode = "http://sistema.agrivem.cl/node-back/api/v1/"
  // urlBase = "http://agrivem-test.kuvesoft.cl/backend/"; // TEST
  urlBase = environment.urlBase;
  urlNode = environment.urlNode
  apiUrl = this.urlBase + "web/index.php?r="
  urlImagen = this.urlBase + "assets/images/"
}
