<div class="login-background">

	<div class="card shadow rounded border-top">
		<div class="card-header">Inicio de Sesión</div>
		<div class="card-body">
			<form (ngSubmit)="iniciarSesion()" [formGroup]="loginForm">
				<div class="form-group mb-2">
					<label for="username">Nombre de Usuario</label>
					<input type="text" class="form-control" id="username" formControlName="username"
						[ngClass]="{'is-invalid': loginForm.controls['username'].errors && loginForm.controls['username'].touched}">
					<div class="invalid-feedback">
						El nombre de usuario no puede estar en blanco.
					</div>
					<div class="mb-3" *ngIf="!loginForm.controls['username'].errors || !loginForm.controls['username'].touched"></div>
				</div>
			
				<div class="form-group mb-2">
					<label for="password">Contraseña</label>
					<input type="password" class="form-control" id="password" formControlName="password"
						[ngClass]="{'is-invalid': loginForm.controls['password'].errors && loginForm.controls['password'].touched}">
					<div class="invalid-feedback">
						La contraseña no puede estar en blanco.
					</div>
					<div class="mb-3" *ngIf="!loginForm.controls['password'].errors || !loginForm.controls['username'].touched"></div>
				</div>
			
				<button type="submit" class="btn btn-success shadow-sm">Iniciar Sesión</button>
				<!-- <button type="button" class="btn btn-link" (click)="recuperarContrasena()">Recuperar contraseña</button> -->
			</form>
		</div>
	</div>
</div>