import { NgModule } from '@angular/core';
import { HomeComponent } from './components/home/home.component';
import { AuthGuardService } from './utils/auth/auth-guard.service';
import { LoginComponent } from './utils/login/login.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'productos', loadChildren: () => import('./components/productos/productos.module').then(m => m.ProductosModule), canActivate: [AuthGuardService]
  },
  {
    path: 'usuarios', loadChildren: () => import('./components/usuarios/usuarios.module').then(m => m.UsuariosModule), canActivate: [AuthGuardService]
  },
  {
    path: 'cajas', loadChildren: () => import('./components/cajas/cajas.module').then(m => m.CajasModule), canActivate: [AuthGuardService]
  },
  {
    path: 'caja-arica', loadChildren: () => import('./components/caja-arica/caja-arica.module').then(m => m.CajaAricaModule), canActivate: [AuthGuardService]
  },
  {
    path: 'produccion-packing',
    loadChildren: () => import('./components/produccion-packing/produccion-packing.module').then(m => m.ProduccionPackingModule), canActivate: [AuthGuardService]
  },
  {
    path: 'cobranza',
    loadChildren: () => import('./components/deudas/deudas.module').then(m => m.DeudasModule), canActivate: [AuthGuardService]
  },
  {
    path: 'departamento',
    loadChildren: () => import('./components/departamento/departamento.module').then(m => m.DepartamentoModule), canActivate: [AuthGuardService]
  },
  {
    path: 'gasto',
    loadChildren: () => import('./components/gasto/gasto.module').then(m => m.GastoModule), canActivate: [AuthGuardService]
  },
  {
    path: 'sii',
    loadChildren: () => import('./components/sii/sii.module').then(m => m.SiiModule), canActivate: [AuthGuardService]
  },
  {
    path: 'inventario',
    loadChildren: () => import('./components/inventario/inventario.module').then(m => m.InventarioModule), canActivate: [AuthGuardService]
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'recover', component: LoginComponent
  },
  {
    path: 'proveedor',
    loadChildren: () => import('./components/proveedor/proveedor.module').then(m => m.ProveedorModule), canActivate: [AuthGuardService],
    // canActivate: [RoleGuardService]
  },
  {
    path: 'compras',
    loadChildren: () => import('./components/compras/compras.module').then(m => m.ComprasModule), canActivate: [AuthGuardService],
    // canActivate: [RoleGuardService]
  },
  {
    path: 'ventas',
    loadChildren: () => import('./components/venta/venta.module').then(m => m.VentaModule), canActivate: [AuthGuardService],
    // canActivate: [RoleGuardService]
  },
  {
    path: 'cliente',
    loadChildren: () => import('./components/clientes/clientes.module').then(m => m.ClientesModule), canActivate: [AuthGuardService]
  },
  {
    path: 'terreno',
    loadChildren: () => import('./components/terrenos/terrenos.module').then(m => m.TerrenosModule), canActivate: [AuthGuardService]
  }, 
  {
    path: 'agricultor',
    loadChildren: () => import('./components/agricultores/agricultores.module').then(m => m.AgricultoresModule), canActivate: [AuthGuardService]
  },
  {
    path: 'stock-Arica',
    loadChildren: () => import('./components/stock/stock.module').then(m => m.StockModule), canActivate: [AuthGuardService]
  },
  {
    path: 'stock-Santiago',
    loadChildren: () => import('./components/stock-externo/stock-externo.module').then(m => m.StockExternoModule), canActivate: [AuthGuardService]
  },
  {
    path: 'movimiento-banco',
    loadChildren: () => import('./components/banco/banco.module').then(m => m.BancoModule), canActivate: [AuthGuardService]
  },
  {
    path: 'merma',
    loadChildren: () => import('./components/merma/merma.module').then(m => m.MermaModule), canActivate: [AuthGuardService]
  },
  {
    path: 'cheques',
    loadChildren: () => import('./components/cheque/cheque.module').then(m => m.ChequeModule), canActivate: [AuthGuardService]
  }, 
  {
    path: 'despachos',
    loadChildren: () => import('./components/despacho/despacho.module').then(m => m.DespachoModule), canActivate: [AuthGuardService]
  },
  {
    path: 'caja',
    loadChildren: () => import('./components/caja/caja.module').then(m => m.CajaModule), canActivate: [AuthGuardService]
  },
  {
    path: 'actualizar-stock',
    loadChildren: () => import('./components/actualizar-stock/actualizar-stock.module').then(m => m.ActualizarStockModule), canActivate: [AuthGuardService]
  },
  {
    path: 'permisos',
    loadChildren: () => import('./components/permisos/permisos.module').then(m => m.PermisosModule), canActivate: [AuthGuardService]
  },
  {
    path: 'reportes',
    loadChildren: () => import('./components/nuevos-reportes/nuevos-reportes.module').then(m => m.NuevosReportesModule) 
  },
  {
    path: 'reportes1',
    loadChildren: () => import('./components/reportes/reportes.module').then(m => m.ReportesModule)
  },
  {
    path: 'reportes2',
    loadChildren: () => import('./components/reporte/reporte.module').then(m => m.ReporteModule), canActivate: [AuthGuardService]
  },
  {
    path: 'perdida',
    loadChildren: () => import('./components/perdida/perdida.module').then(m => m.PerdidaModule), canActivate: [AuthGuardService]
  },
  {
    path: 'deposito',
    loadChildren: () => import('./components/deposito/deposito.module').then(m => m.DepositoModule), canActivate: [AuthGuardService]
  },
  {
    path: 'pagos',
    loadChildren: () => import('./components/pagos/pago.module').then(m => m.PagoModule), canActivate: [AuthGuardService]
  },
  {
    path: 'tipo-gasto',
    loadChildren: () => import('./components/tipo-gasto/tipo-gasto.module').then(m => m.TipoGastoModule), canActivate: [AuthGuardService]
  },
  {
    path: 'notas-credito',
    loadChildren: () => import('./components/notas-credito/notas-credito.module').then(m => m.NotasCreditoModule), canActivate: [AuthGuardService]
  },
  {
    path: 'caja-chica',
    loadChildren: () => import('./components/caja-chica/caja-chica.module').then(m => m.CajaChicaModule), canActivate: [AuthGuardService]
  },
  {
    path: 'reportabilidad',
    loadChildren: () => import('./components/reportabilidad/reportabilidad.module').then(m => m.ReportabilidadModule), canActivate: [AuthGuardService]
  },
  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuardService]
  },
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: '**', redirectTo: 'home', pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
