import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { Observable } from 'rxjs';
import { SesionService } from '../sesion/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  controller: string = "notificacion/";
  get httpOptions() {
    return { 
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.sesion.token
      })
    }
  };
  get token() { return localStorage.getItem('token') };
  
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private sesion: SesionService
  ) { 
    // this.token = localStorage.getItem('token')
  }

  index(): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "index", { token: this.token }, this.httpOptions)
  }

  count(): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "count", { token: this.token }, this.httpOptions)
  }

}