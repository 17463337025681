import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from './usuario';
import { ConfigService } from 'src/app/utils/config/config.service';
import { SesionService } from 'src/app/utils/sesion/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  controller: string = "usuario/";

  get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.sesion.token,
      })
    }
  };

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private sesion: SesionService
  ) { }

  index(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + "index", this.httpOptions)
  }

  indexLocal(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + "index-local", this.httpOptions)
  }

  insert(usuario: Usuario): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "create", { element: usuario }, this.httpOptions);
  }
  
  uploadImagen(idUsuario: number, nombreImagen: string, imagenFile: any) {
    let formData = new FormData();
    formData.append("imagen[]", imagenFile, nombreImagen);
    formData.append("token", this.sesion.token);
    formData.append("scope", this.sesion.scope);
    formData.append("id", idUsuario.toString());
    return this.http.post(this.config.apiUrl + this.controller + 'uploadimage', formData);
  }
  update(usuario: Usuario): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "update", { element: usuario }, this.httpOptions)
  }
  view(id: number): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "view", { id: id }, this.httpOptions)
  }
  delete(id: number): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "deactivate", { id: id }, this.httpOptions)
  }
  activate(id: number): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "activate", { id: id }, this.httpOptions)
  }
  deactivate(id: number): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "deactivate", { id: id }, this.httpOptions)
  }
  changePassword(pass: any) {
    return this.http.post(this.config.apiUrl + this.controller + "change-password", { pass: pass, id: this.sesion.id }, this.httpOptions)
  }
  sendConfirmationEmail(correo: any, username: any, password: any): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + 'sendemail', { email: correo, username: username, password: password, empresa: this.sesion.scope }, this.httpOptions);
  }
}
