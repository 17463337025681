import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SesionService {

  IVA: number = 19

  username: string = null;
  id: number = null;
  token: string = null;
  rol: number = null;

  sucursal: number = null;
  scopes: any[] = [];
  scope: any = null;
  scopeIndex: number;

  notifications: any[] = [];
  sub = new Subject();
  menu: any[];

  observa = this.sub.asObservable();
  controller: string = "usuario/"
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
      })
  };

  get headers () {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token
      })
    }
  };

  get headersBlob() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token,
      }),
      responseType: 'blob' as 'json',
    }
  };

  get httpOptionsBlob() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token,
      }),
      responseType: 'blob' as 'json',
    }
  };

  get headersFile() {
    return {
      headers: new HttpHeaders({
        'Authorization': this.token
      })
    }
  }

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    // private cookie: CookieService
  ) {
    this.loadDataSesion();
  }

  loadDataSesion() {
    this.token = localStorage.getItem("token");
    this.username = localStorage.getItem("username");
    this.id = parseInt(localStorage.getItem("id"));
    this.rol = parseInt(localStorage.getItem("rol"));
    this.sub.next(JSON.parse(localStorage.getItem("menus")));
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token ? this.token : ''
      })
    };
  }

  login(credenciales): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "login", credenciales, this.httpOptions);
  }

  isLogged(): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "islogged", { token: this.token }, this.httpOptions)
  }

  saveSesion(token, id, username, menus, rol) {
    localStorage.setItem("token", token);
    localStorage.setItem("username", username);
    localStorage.setItem("id", id);
    localStorage.setItem("rol", rol);
    localStorage.setItem("menus", JSON.stringify(menus));

    this.token = token;
    this.username = username;
    this.id = parseInt(id);
    this.rol = parseInt(rol);
    this.sub.next(menus);
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token
      })
    };
  }

  clearSesion() {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("id");
    localStorage.removeItem("rol");
    localStorage.removeItem("menus");

    this.username = null;
    this.id = null;
    this.token = null;
    this.rol = null;
    this.menu = [];
  }

  getScopes(): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "get-scopes", { token: this.token }, this.httpOptions)
  }

  getMenuByUser(): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token ? this.token : ''
      })
    };
    return this.http.get(this.config.apiUrl + this.controller + "get-menus", this.httpOptions)
  }

  //crear observable

}
