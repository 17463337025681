import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService, AlertService } from 'ngx-kuv-tools';
import { SesionService } from 'src/app/utils/sesion/sesion.service';
import { UsuarioPassGenComponent } from '../usuario-pass-gen/usuario-pass-gen.component';
import { UsuarioService } from '../usuario.service';

@Component({
  selector: 'app-usuario-profile',
  templateUrl: './usuario-profile.component.html',
  styleUrls: ['./usuario-profile.component.scss']
})
export class UsuarioProfileComponent implements OnInit {

  form: FormGroup = null;

  constructor(
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private alerts: AlertService,
    public activeModal: NgbActiveModal,
    public service: UsuarioService,
    private modalService: NgbModal,
    private sesion: SesionService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      apellido: ['', [Validators.required]],
      username: ['', [Validators.required]],
      rol_id: ['', [Validators.required]],
      email: ['', [Validators.required]],
      fono1: [''],
      fono2: [''],
    });
    setTimeout(() => this.getUsuario(), 0);
  }

  getUsuario() {
    this.loading.show();
    this.service.view(this.sesion.id).subscribe((res) => {
      this.form.patchValue(res.model);
      this.form.disable();
    }, (err) => {
      this.alerts.addAlert("No se pudieron cargar los datos de su perfil.", "danger");
    }, () => {
      this.loading.hide();
    })
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  guardar() {
    if (this.form.valid) {
      this.loading.show();
      this.service.update(this.form.value).subscribe((res) => {
        this.loading.hide();
        this.activeModal.close(res.model);
      }, (err) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert("No se pudo guardar el usuario.", "danger");
      })
    } else {
      this.form.markAllAsTouched();
    }
  }

  generatePass() {
    let modalRef = this.modalService.open(UsuarioPassGenComponent, { size: 'md', centered: true, backdrop: 'static' });
    modalRef.result.then((res) => {
      this.form.patchValue({ password: res, repeat: res });
    }, (reason) => { })
  }
}
