import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { SesionService } from 'src/app/utils/sesion/sesion.service';
declare var OneSignal: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup = null;

  constructor(
    private formBuilder: FormBuilder,
    private service: SesionService,
    private loading: LoadingService,
    private alerts: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      username: ['', Validators.required]
    });
  }

  iniciarSesion() {
    if (this.loginForm.valid) {
      this.loading.show();
      this.service.login(this.loginForm.value).subscribe((res) => {
        this.service.saveSesion(res.token, res.idUsuario, res.username, [], res.rol);
        
        this.service.getMenuByUser().subscribe((resMenu: any) => {
          this.loading.hide();
          this.service.saveSesion(res.token, res.idUsuario, res.username, resMenu.result, res.rol);
          this.router.navigate(['home']);
          this.alerts.addAlert("Bienvenido " + res.username, "success");

          // try {
          //   (<any>window).OneSignal = (<any>window).OneSignal || [];
          //   OneSignal.push(function () {
          //     // OneSignal.init({
          //     //   appId: "76d0f519-b70a-42c7-a4e7-4b6cc8ce6c44",
          //     // });
          //     OneSignal.sendTag("id", res.idUsuario);
          //   });
          // } catch (error) {
          //   console.log(error);
          // }
        }, (err) => {
          this.loading.hide();
          console.error(err);
        });
      }, (err) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert("No se ha podido iniciar sesión.", "danger");
      })
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  recuperarContrasena() {

  }

  isLogged() {
    if (this.service.token) {
      this.loading.show();
      this.service.isLogged().subscribe((res) => {
        this.service.getMenuByUser().subscribe((resMenu: any) => {
          this.service.saveSesion(res.token, res.idUsuario, res.username, resMenu.result, res.rol);
          this.loading.hide();
          this.alerts.addAlert("Bienvenido de vuelta " + res.username, "info");
        }, (err) => console.error(err));
      }, (err) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert("No se ha podido reiniciar sesión.", "danger");
      })
    }
  }

}
