import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styles: [
  ]
})
export class VersionComponent implements OnInit {

  public version: string = '1.0.0';

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

}
