<div id="project-base">
  <div id="project-sidebar">
    <kuv-sidebar [logo]="'assets/system/logo.svg'" [logo-sm]="'assets/system/logo-sm.svg'" [routes]="routes">
    </kuv-sidebar>
  </div>
  <div id="project-header">
    <ng-template #popTitle>Notificaciones</ng-template>
    <ng-template #popContent>
    </ng-template>

    <div *ngIf="sesion.scopes.length > 0" class="mr-3">
      <label for="scope"></label>
      <select class="form-select" id="scope" [(ngModel)]="sesion.scopeIndex">
        <ng-container *ngFor="let s of sesion.scopes; let i = index">
          <option [value]="i">{{s.name}}</option>
        </ng-container>
      </select>
    </div>

    <button type="button" class="tool-btn" placement="bottom-right" [ngbPopover]="bodyPopover" [popoverTitle]="popTitle" [autoClose]="'outside'" popoverClass="custom-popover" (click)="getNotification()">
      <i class="fas fa-bell"></i>
      <span *ngIf="(numNotificaciones != 0)" class=" ml-1 badge bg-light text-dark">{{numNotificaciones}}</span>
    </button>
    <ng-template #bodyPopover>
      <div *ngIf="loadingNotifications" class="w-100 d-flex justify-content-center">
        <i class="fas fa-circle-notch rotate" style="font-size: xx-large;"></i>
      </div>
      <div *ngIf="notificacionProductosCriticos.length != 0">
        <b>Productos Críticos:</b>
        <ng-container *ngFor="let n of notificacionProductosCriticos">
          <div class="popover-body-button">
            <button class="alert popover-body-element w-100 text-left my-1" [ngClass]="{'alert-danger': true}"
            [routerLink]="getLink(n)">
              El producto <strong>{{n.nombre}}</strong>
              se encuentra en stock crítico.
            </button>
          </div>
        </ng-container>
      </div>

      <div *ngIf="notificacionGamelasCriticas.length != 0">
        <hr class="rounded">
        <b>Gamelas Críticas:</b>
        <ng-container *ngFor="let n of notificacionGamelasCriticas">
          <div class="popover-body-button">
            <button class="alert popover-body-element w-100 text-left my-1" [ngClass]="{'alert-danger': true}"
              [routerLink]="getLink(n)">
              La gamela <strong>{{n.nombre}}</strong>
              se encuentra en stock crítico.
            </button>
          </div>
        </ng-container>
      </div>

      <div *ngIf="notificacionCheques.length != 0">
        <hr class="rounded">
        <b>Cheques:</b>
        <ng-container *ngFor="let n of notificacionCheques">
          <div class="popover-body-button">
            <button class="alert popover-body-element w-100 text-left my-1"
              [ngClass]="{'alert-danger': estaVencida(n), 'alert-warning': !estaVencida(n)}"
              routerLink="/cheques/index">
              <div *ngIf="estaVencida(n); else noVencida">
                El cheque <strong>{{n.serie}}</strong>
                venció el <strong>{{formatoFecha(n.vencimiento)}}</strong>.
              </div>
              <ng-template #noVencida>
                El cheque <strong>{{n.serie}}</strong>
                vencerá el <strong>{{formatoFecha(n.vencimiento)}}</strong>.
              </ng-template>
            </button>
          </div>
        </ng-container>
      </div>

      <div *ngIf="notificacionCreditos.length != 0">
        <hr class="rounded">
        <b>Cuotas:</b>
        <ng-container *ngFor="let n of notificacionCreditos">
          <div class="popover-body-button">
            <button class="alert popover-body-element w-100 text-left my-1"
              [ngClass]="{'alert-danger': estaVencida(n), 'alert-warning': !estaVencida(n)}" routerLink="/cobranza/index">
              <div *ngIf="estaVencida(n); else noVencida">
                El crédito <strong>{{n.id}}</strong>
                venció el <strong>{{formatoFecha(n.vencimiento)}}</strong>.
              </div>
              <ng-template #noVencida>
                El crédito <strong>{{n.id}}</strong>
                vencerá el <strong>{{formatoFecha(n.vencimiento)}}</strong>.
              </ng-template>
            </button>
          </div>
        </ng-container>
      </div>

      <div *ngIf="notificacionCuotas.length != 0">
        <hr class="rounded">
        <b>Deudas:</b>
        <ng-container *ngFor="let n of notificacionCuotas">
          <div class="popover-body-button">
            <button class="alert popover-body-element w-100 text-left my-1"
              [ngClass]="{'alert-danger': estaVencida(n), 'alert-warning': !estaVencida(n)}"
              routerLink="/cobranza/index">
              <div *ngIf="estaVencida(n); else noVencida">
                La deuda <strong>{{n.id}}</strong>
                venció el <strong>{{formatoFecha(n.fecha)}}</strong>.
              </div>
              <ng-template #noVencida>
                La deuda <strong>{{n.id}}</strong>
                vencerá el <strong>{{formatoFecha(n.fecha)}}</strong>.
              </ng-template>
            </button>
          </div>
        </ng-container>
      </div>

      <div *ngIf="notificacionGamelasArrendadas.length != 0">
        <hr class="rounded">
        <b>Gamelas Arrendadas:</b>
        <ng-container *ngFor="let n of notificacionGamelasArrendadas">
          <div class="popover-body-button">
            <button class="alert popover-body-element w-100 text-left my-1"
              [ngClass]="{'alert-danger': estaVencida(n), 'alert-warning': !estaVencida(n)}" routerLink="/caja/active">
              <div *ngIf="estaVencida(n); else noVencida">
                La gamela arrendada <strong>{{n.id}}</strong>
                debió haber sido devuelta el <strong>{{formatoFecha(n.vencimiento)}}</strong>.
              </div>
              <ng-template #noVencida>
                La gamela arrendada <strong>{{n.id}}</strong>
                debe ser devuelta el <strong>{{formatoFecha(n.vencimiento)}}</strong>.
              </ng-template>
            </button>
          </div>
        </ng-container>
      </div>

      <div *ngIf="notificacionGamelasPrestadas.length != 0">
        <hr class="rounded">
        <b>Gamelas Prestadas:</b>
        <ng-container *ngFor="let n of notificacionGamelasPrestadas">
          <div class="popover-body-button">
            <button class="alert popover-body-element w-100 text-left my-1"
              [ngClass]="{'alert-danger': estaVencida(n), 'alert-warning': !estaVencida(n)}" routerLink="/caja/active">
              <div *ngIf="estaVencida(n); else noVencida">
                La gamela prestada <strong>{{n.id}}</strong>
                debió haber sido devuelta el <strong>{{formatoFecha(n.vencimiento)}}</strong>.
              </div>
              <ng-template #noVencida>
                La gamela prestada <strong>{{n.id}}</strong>
                debe ser devuelta el <strong>{{formatoFecha(n.vencimiento)}}</strong>.
              </ng-template>
            </button>
          </div>
        </ng-container>
      </div>
    </ng-template>

    <div ngbDropdown class="d-inline-block mx-2">
      <button class="btn btn-kuv" id="dropdownBasic1" ngbDropdownToggle>
        <i class="fas fa-user"></i><span class="ml-2 d-none d-sm-inline">{{sesion.username}}</span>
      </button>

      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="verPerfil()">
          <span class="mr-1">
            <i class="fas fa-user-alt"></i>
          </span>Ver Perfil
        </button>
        <button ngbDropdownItem (click)="changePass()">
          <span class="mr-1">
            <i class="fas fa-key"></i>
          </span>Cambiar Contraseña
        </button>
        <button ngbDropdownItem (click)="verVersion()">
          <span class="mr-1">
            <i class="fas fa-info-circle"></i>
          </span>Versión
        </button>
        <button ngbDropdownItem (click)="logout()">
          <span class="mr-1">
            <i class="fas fa-sign-out-alt"></i>
          </span>Cerrar Sesión
        </button>
      </div>
    </div>
  </div>
  <div id="project-container">
    <kuv-loading></kuv-loading>
    <kuv-alert></kuv-alert>
    <router-outlet></router-outlet>
  </div>
</div>