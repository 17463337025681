<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title">Agrivem</h4>
		<button type="button" class="btn-close" aria-label="Close"
			(click)="activeModal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<h4>Versión: {{version}}</h4>
		<h4>Desarrollado Por <a href="https://kuvemar.com/">Kuvemar</a></h4>
		<h4>Soporte: <a href="mailto:soporte@kuvemar.com">soporte@kuvemar.com</a></h4>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-danger" (click)="activeModal.dismiss('Cancel click')">Cerrar</button>
	</div>
</div>