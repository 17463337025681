import { Component, OnInit } from '@angular/core';
import { AlertService, LoadingService } from 'ngx-kuv-tools';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  srcIMG = '/assets/system/tomandino.png';
  times = 0;

  constructor(
    private alerts: AlertService,
    private loading: LoadingService,

  ) { }

  onClickEvent(){
    if(this.times > 25){
      this.srcIMG = '/assets/system/a.mta';
      setTimeout(() => {
        this.times = 0;
        this.srcIMG = '/assets/system/tomandino.png';
      }, 8000)
    }
    // this.times++;
  }

  ngOnInit(): void {

  }

}
