import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor() { }

  colors = ['red', 'green', 'blue', 'yellow', 'brown', 'orange', 'purple', 'deep-purple', 'grey', 'blue-grey', 'light-blue', 'pink', 'indigo', 'cyan', 'teal', 'light-green', 'lime', 'amber', 'deep-orange'];

  setTheme(color) {
    let root = document.documentElement;

    root.style.setProperty('--kuv-bg-logo', "var(--" + color + "-900)");
    // root.style.setProperty('--kuv-cl-logo', "black");

    root.style.setProperty('--kuv-bg-options', "var(--" + color + "-800)");
    // root.style.setProperty('--kuv-cl-options', "black");
    root.style.setProperty('--kuv-bg-children', "var(--" + color + "-700)");
    root.style.setProperty('--kuv-bg-hover', "var(--" + color + ")");


    // root.style.setProperty('--kuv-bg-logo', "#4BB874");
    // // root.style.setProperty('--kuv-cl-logo', "black");

    // root.style.setProperty('--kuv-bg-options', "#5FE894");
    // // root.style.setProperty('--kuv-cl-options', "black");
    // root.style.setProperty('--kuv-bg-children', "#B5FFD1");
    // root.style.setProperty('--kuv-bg-hover', "var(--kuv-bg-children)");
    // // root.style.setProperty('--kuv-bg-hover', "var(--blue-grey-200)");
    // root.style.setProperty('--kuv-cl-hover', "var(--blue-grey-900)");
  }

  setKuvTheme() {
    let root = document.documentElement;

    root.style.setProperty('--kuv-bg-logo', "rgb(45, 22, 99)");

    root.style.setProperty('--kuv-bg-options', "rgb(45, 22, 99)");
    root.style.setProperty('--kuv-cl-options', "#1eda81");

    root.style.setProperty('--kuv-bg-children', "rgb(55, 35, 130)");
    root.style.setProperty('--kuv-cl-children', "#1eda81");

    root.style.setProperty('--kuv-bg-toggle', "rgb(55, 35, 130)");
    root.style.setProperty('--kuv-cl-toggle', "#1eda81");

    root.style.setProperty('--kuv-bg-button', "rgb(55, 35, 130)");
    root.style.setProperty('--kuv-cl-button', "white");

    root.style.setProperty('--kuv-bg-hover', "rgb(70, 55, 190)");
    root.style.setProperty('--kuv-bg-active', "rgb(70, 55, 190)");
  }
}
