import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService, AlertService } from 'ngx-kuv-tools';
import { UsuarioService } from '../usuario.service';

@Component({
  selector: 'app-usuario-pass-gen',
  templateUrl: './usuario-pass-gen.component.html',
  styleUrls: ['./usuario-pass-gen.component.scss']
})
export class UsuarioPassGenComponent implements OnInit {

  form: FormGroup = null;
  
  constructor(
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private alerts: AlertService,
    public activeModal: NgbActiveModal,
    public service: UsuarioService
  ) {}
  
  ngOnInit() {

    this.form = this.formBuilder.group({
      accept: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    this.form.patchValue({password: this.makeid(10)});
  }
  
  guardar(){
    if(this.form.valid){
      this.activeModal.close(this.form.value.password);
    } else {
      this.form.markAllAsTouched();
    }
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var characters2 = '!?.()=+$';
    var charactersLength = characters.length;
    result += characters2.charAt(Math.floor(Math.random() * 8));
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
}
