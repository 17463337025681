import { DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NgbModule, NgbPopoverModule, NgbButtonsModule, NgbDropdownModule, NgbModalModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxKuvToolsModule } from "ngx-kuv-tools";
import { NgxKuvUtilsModule } from "ngx-kuv-utils";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./components/home/home.component";
import { UsuarioPassChangeComponent } from "./components/usuarios/usuario-pass-change/usuario-pass-change.component";
import { UsuarioProfileComponent } from "./components/usuarios/usuario-profile/usuario-profile.component";
import { LoginComponent } from "./utils/login/login.component";
import { ModalInputComponent } from "./utils/modal-input/modal-input.component";
import { InfoAlertSiiModule } from "./utils/info-alert-sii/info-alert-sii.module";

const maskConfig: Partial<IConfig> = {};

@NgModule({
  declarations: [
    AppComponent,
    UsuarioPassChangeComponent,
    UsuarioProfileComponent,
    HomeComponent,
    LoginComponent,
    ModalInputComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxKuvUtilsModule,
    NgxKuvToolsModule,
    NgbPopoverModule,
    NgbButtonsModule,
    NgbDropdownModule,
    HttpClientModule,
    NgbModalModule,
    NgbNavModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    NgSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    InfoAlertSiiModule
  ],
  providers: [
    DatePipe,
    JwtHelperService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    UsuarioPassChangeComponent,
    UsuarioProfileComponent
  ]
})
export class AppModule { }
